import React, {useState} from 'react';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from "react-icons/bs"
import "./Carousel.css";

export const Carousel = ({ data }) => {
   const [slide, setSlide] = useState(0)

   const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1)
   }

   const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1)
   }


    return (
        <div className="carousel">
            <BsArrowLeftCircleFill 
                onClick={prevSlide}
                className="arrow arrow-left" 
            />
            {data.map((item, idx) => {
                return (
                <img 
                    src={item.src} 
                    alt={item.alt} 
                    key={idx} 
                    className= {slide === idx ? "slide" : "slide slide-hidden"}
                    style={{ width: '90%', height: 'auto' }}
                /> 
                )
            })}
            <BsArrowRightCircleFill 
                onClick={nextSlide}
                className="arrow arrow-right"
            />
            
            {/* <span className="indicators">
                {data.map((_, idx) => {
                    return (
                    <button
                        key={idx}
                        onClick={null}
                        className= {slide === idx ? "indicator" : "indicator-inactive"}
                        >
                        </button>
                    )
                })}
            </span> */}
        
        </div>
    )
}
